@tailwind base;
@tailwind components;

@tailwind utilities;

@layer base {
  h1 {
    @apply text-xl;
    @apply text-text-primary-light;
    @apply dark:text-text-primary-dark;
  }
  h2 {
    @apply text-lg;
    @apply text-text-primary-light;
    @apply dark:text-text-primary-dark;
  }
  p {
    @apply text-base;
  }
  :focus-visible,
  [type="text"]:focus-visible,
  [type="email"]:focus-visible,
  [type="tel"]:focus-visible,
  input:focus-visible,
  button:focus-visible,
  select:focus-visible,
  textarea:focus-visible {
    @apply outline-none;
    @apply ring;
    @apply ring-offset-0;
    @apply ring-focus-light;
    @apply dark:ring-focus-dark;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition:
      background-color 600000s 0s,
      color 600000s 0s;
  }
  .dark {
    color-scheme: dark;
  }

  /* TEXT */
  .text-primary {
    @apply text-text-primary-light;
    @apply dark:text-text-primary-dark;
  }
  .text-accent-primary {
    @apply text-accent-primary-light;
    @apply dark:text-accent-primary-dark;
  }
  .text-secondary {
    @apply text-text-secondary-light;
    @apply dark:text-text-secondary-dark;
  }
  .text-tertiary {
    @apply text-text-tertiary-light;
    @apply dark:text-text-tertiary-dark;
  }
  .text-error {
    @apply text-text-error-light;
    @apply dark:text-text-error-dark;
  }

  /* SURFACES (BACKGROUNDS) */
  .surface-primary {
    @apply bg-surface-primary-light;
    @apply dark:bg-surface-primary-dark;
  }
  .surface-accent {
    @apply bg-surface-accent-light;
    @apply dark:bg-surface-accent-dark;
  }
  .surface-secondary {
    @apply bg-surface-secondary-light;
    @apply dark:bg-surface-secondary-dark;
  }
  .surface-secondary-accent {
    @apply bg-surface-secondary-accent-light;
    @apply dark:bg-surface-secondary-accent-dark;
  }

  /* BORDERS & DIVIDERS */
  .border-primary {
    @apply border-border-primary-light;
    @apply dark:border-border-primary-dark;
  }
  .border-accent {
    @apply border-border-accent-light;
    @apply dark:border-border-accent-dark;
  }
  .divide-primary {
    @apply divide-divide-primary-light;
    @apply dark:divide-border-primary-dark;
  }

  /* BUTTONS */
  .accent-button {
    @apply bg-accent-button-light;
    @apply dark:bg-accent-button-dark;
  }
  .accent-button-secondary {
    @apply bg-accent-button-secondary-light;
    @apply dark:bg-accent-button-secondary-dark;
  }
  .accent-button-medium {
    @apply bg-accent-button-medium-light;
    @apply dark:bg-accent-button-medium-dark;
  }
}
